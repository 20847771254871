/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, { useState, useEffect } from 'react'

const Infos2 = ({posX,posY,data,showInfo}) => {
    
    const [largeur, setlargeur] = useState(240)
    const [hauteur, sethauteur] = useState(28)

    const [largeur2, setlargeur2] = useState(210)
    const [hauteur2, sethauteur2] = useState(28)

    const [largeur3, setlargeur3] = useState(210)
    const [hauteur3, sethauteur3] = useState(28)

    const [largeur4, setlargeur4] = useState(350)
    const [hauteur4, sethauteur4] = useState(28)

    if(posX > 10 && posX < 33 && posY >= 130 && posY <= 160){
            return (
                <svg x={ posX+2 } y={ posY-2-hauteur } width={largeur} height={hauteur}>
                    <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                    <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>Déphasage courant - tension</text>
                </svg>
            )
    }else if(posX > 14 && posX < 66 && posY >= 85 && posY <= 112){
        return (
            <svg x={ posX+2 } y={ posY-2-hauteur2 } width={largeur2} height={hauteur2}>
                <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>Amplitude du courant</text>
            </svg>
        )
    }else if(posX > 14 && posX < 66 && posY >= 34 && posY <= 58){
        return (
            <svg x={ posX+2 } y={ posY-2-hauteur3 } width={largeur3} height={hauteur3}>
                <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>Amplitude de la tension</text>
            </svg>
        )
    }else if(posX > 315 && posX < 332 && posY >= 34 && posY <= 58){
        return (
            <svg x={ posX-largeur4/2 } y={ posY-4-hauteur4 } width={largeur4} height={hauteur4}>
                <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>Fréquence du courant et de la tension</text>
            </svg>
        )
    }else{
        return null;
    }
    
}
 
export default Infos2;