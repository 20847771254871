import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('courantMax')) || 6,
}

export const iSlice = createSlice({
  name: 'i',
  initialState,
  reducers: {
    plusI: (state) => {
        if(state.value < 12){
            state.value = parseFloat((state.value+0.1).toFixed(1));
            localStorage.setItem("courantMax", parseFloat(state.value).toFixed(1));
        }
    },
    moinsI: (state) => {
        if(state.value > 2){
            state.value = parseFloat((state.value-0.1).toFixed(1));
            localStorage.setItem("courantMax", parseFloat(state.value).toFixed(1));
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusI, moinsI } = iSlice.actions

export default iSlice.reducer