/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React from 'react'

const Fonction = ({width,height,color,fct,changefct}) => {

    const handleEnter = (e) => {
        changefct(fct);
    }

    const handleOut = (e) => {
        changefct(-1);
    }

    return ( 
        <svg>
            <g style={{ cursor : "pointer"}} onPointerOver={handleEnter} onPointerOut={handleOut}>
                <path stroke={color} fill={color} d={`M${width-65} 0  L${width-15} 0 Q ${width} 0 ${width} 15 L${width} 70 Q ${width-55} 60 ${width-65} 0`} />
                <text fill='white' x={width-40} y={32} style={{fontFamily:'Fafers', fontSize: "20px", fontWeight : "bold"}}>f(x)</text>
            </g>
        </svg>
     );
}
 
export default Fonction;