import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('tensionMax')) || 6,
}

export const uSlice = createSlice({
  name: 'u',
  initialState,
  reducers: {
    plusU: (state) => {
        if(state.value < 12){
            state.value = parseFloat((state.value+0.1).toFixed(1));
            localStorage.setItem("tensionMax", parseFloat((state.value).toFixed(1)));
        }
    },
    moinsU: (state) => {
        if(state.value > 2){
            state.value = parseFloat((state.value-0.1).toFixed(1));
            localStorage.setItem("tensionMax", parseFloat((state.value).toFixed(1)));
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusU, moinsU } = uSlice.actions

export default uSlice.reducer