/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, { Fragment, useEffect, useState } from 'react';
import './App.css';
import Courant from './components/Courant';
import Puissance from './components/Puissance';
import Settings from './components/Settings';
import Tension from './components/Tension';


function App() {

  const [width, setwidth] = useState(1200)

  const [height, setheight] = useState(700)

  const [realHeight, setrealHeight] = useState(window.innerHeight)

  const [realWidth, setrealWidth] = useState(window.innerWidth)

  const [fct, setfct] = useState(-1)
  
  useEffect(() => {

    window.addEventListener('resize', handleWindowSizeChange);
  
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])
  
  const handleWindowSizeChange = () => {

    setrealHeight(window.innerHeight);
    setrealWidth(window.innerWidth);

  }

  const changefct = (value) => {
      setfct(value);
  }
  
  return (
    <div className="svg-container">
      <svg id="container" viewBox="0 0 1200 700" preserveAspectRatio="xMinYMin meet" className="svg-content">             
          <Fragment>
                  <Tension x={`${20}`} y={`${20}`} width={`${width/2-30}`} height={`${height/2-30}`} modifFct={changefct}/>
                  <Courant x={`${20}`} y={`${10+height/2}`} width={`${width/2-30}`} height={`${height/2-30}`}  modifFct={changefct}/>
                  <Puissance x={`${10+width/2}`} y={`${20}`} width={`${width/2-30}`} height={`${height/2+70}`} modifFct={changefct}/>
                  <Settings x={`${10+width/2}`} y={`${70+40+height/2}`} width={`${width/2-30}`} height={`${height/2-70-60}`} fct={fct}/>
            </Fragment>      
      </svg>
    </div>
  );

}

export default App;
