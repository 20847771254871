import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('dephasage')) || 0,
}

export const phiSlice = createSlice({
  name: 'phi',
  initialState,
  reducers: {
    plusPhi: (state) => {
        if(state.value < 0.5){
            state.value = parseFloat((state.value+0.01).toFixed(2));
            localStorage.setItem('dephasage', parseFloat(state.value.toFixed(2)));
        }
    },
    moinsPhi: (state) => {
        if(state.value > -0.5){
            state.value = parseFloat((state.value-0.01).toFixed(2));
            localStorage.setItem('dephasage', parseFloat(state.value.toFixed(2)));
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusPhi, moinsPhi } = phiSlice.actions

export default phiSlice.reducer