/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, {useState,useEffect} from 'react'

const Checkbox = ({x,y,changeValue,value,width,height}) => {

 
    const handleClick = (e) => {
        changeValue();
    }


    
    return ( 
        <svg x={x} y={y} width={width} height={height} viewBox="0 0 30 30"  onClick={handleClick} style={{ cursor : "pointer"}}>
            <rect strokeWidth={0} x="0" y="0" width="30" height="30" rx="10" ry="10" fill={'#000000'} />
            <rect strokeWidth={0} x="1" y="1" width="28" height="28" rx="10" ry="10" fill={value === true ? '#0080ff' : '#ffffff'} />
            {
                value === true && (
                    <path x="0" y="0" stroke='#ffffff' strokeWidth="4" fill="none" d="M 5 15 L 11 23 L 24 6" />
                )
            }
        </svg>
     );
}
 
export default Checkbox;