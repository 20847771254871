import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('frequence')) || 20,
}

export const fSlice = createSlice({
  name: 'f',
  initialState,
  reducers: {
    plusF: (state) => {
        if(state.value < 30){
            state.value = parseFloat((state.value+0.1).toFixed(1));
            localStorage.setItem("frequence", parseFloat(state.value).toFixed(1));
        }
    },
    moinsF: (state) => {
        if(state.value > 10){
            state.value = parseFloat((state.value-0.1).toFixed(1));
            localStorage.setItem("frequence", parseFloat(state.value).toFixed(1));
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusF, moinsF } = fSlice.actions

export default fSlice.reducer