import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('dt')) || 0,
}

export const dtSlice = createSlice({
  name: 'dt',
  initialState,
  reducers: {
        setValue: (state, action) => {
          console.log(action.payload);
          const { dephasage, frequence} = action.payload;
          state.value = parseFloat((dephasage/(2*frequence*0.001)).toFixed(2));
          localStorage.setItem("dt", parseFloat((state.value).toFixed(2)));
        },
  },
})

// Action creators are generated for each case reducer function
export const { setValue } = dtSlice.actions

export default dtSlice.reducer