/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React,{useState,useEffect, Fragment} from 'react';
import Graphique from '../Graphique';
import Fonction from './Fonction';
import { useSelector } from 'react-redux';

const Courant = ({x,y,width,height,modifFct}) => {

    const courant = useSelector((state) => state.i.value)
    const frequence = useSelector((state) => state.f.value)
    const temps = useSelector((state) => state.t.value)
    const dephasage = useSelector((state) => state.phi.value)
    const deltatOk = useSelector((state) => state.deltat.value)
    const dt = useSelector((state) => state.dt.value)

    const [xList, setxList] = useState([])

    const [yList, setyList] = useState([])

    const [deltaX, setdeltaX] = useState(0.1)

    const [config, setconfig] = useState({
        xMin : 0,
        xMax : temps,
        yMin : -1*courant,
        yMax : courant,
        grandeurY : "I",
        uniteY : "A",
        grandeurX : "t",
        uniteX : "s",
    })

    useEffect(() => {

        setconfig({
            xMin : 0,
            xMax : temps,
            yMin : -1*courant,
            yMax : courant,
            grandeurY : "I",
            uniteY : "A",
            grandeurX : "t",
            uniteX : "ms",
        })

        let x = 0;
        let liX = [];
        let liY = [];
      
        while(x <= config.xMax){
            liX.push(x);
            liY.push((courant*Math.cos(2*3.1415926*frequence*x*0.001-dephasage*3.1415926)));
            x += deltaX;
        }

        setxList(liX);
        setyList(liY);


    }, [courant,frequence,temps,dephasage])

    const changefct = (value) => {
        modifFct(value);
    }
    
    return ( 
        <svg  className='courant' x={x} y={y} width={width} height={height}>
            <rect x="0" y="0" width="100%" height="100%" rx="15" ry="15" fill='#FFFFFF' />

            {
                deltatOk === true && dt < 0 && (
                    <rect x={(((1000/frequence)+dt)*((width-80)/(temps))+35)} y={0} width={((-1*dt)*((width-80)/(temps)))} height={height} fill='#ffff00' opacity="0.5"/>
                )
            }

            {
                deltatOk === true && dt > 0 && (
                    <rect x={((1000/frequence)*((width-80)/(temps))+35)} y={0} width={((dt)*((width-80)/(temps)))} height={height} fill='#ffff00' opacity="0.5"/>
                )
             } 

            
            <Graphique config={config} i={1} show={true} mode={1} line="3" lx={xList} ly={yList} colcourbe={'#ff0000'} dxmax={20} dxmin={10} dymax={config.yMax > 6 ? 2 : 1} dymin={config.yMax > 6 ? 0.5 : 0.2} xmin={config.xMin} xmax={config.xMax} ymin={config.yMin} ymax={config.yMax} x0={35} y0={height/2+5} width={width-80} height={height-70} xLabel={`${config.grandeurX}(${config.uniteX})`} yLabel={`${config.grandeurY}(${config.uniteY})`}/>
            

            {
                deltatOk === true && dt >= 6 && (
                    <Fragment>
                        <line stroke='#5a005a' strokeWidth="4" x1={((1000/frequence)*((width-80)/(temps))+35)+2} x2={((1000/frequence)*((width-80)/(temps))+35)+((dt)*((width-80)/(temps)))-2} y1={height-35} y2={height-35} markerEnd="url(#arrowhead32)" markerStart="url(#arrowhead33)"/>
                                    <defs>
                                        <marker id="arrowhead32" markerWidth="2" markerHeight="3" refX="1.5" refY="1.5" orient="auto">
                                            <path strokeWidth="0" fill='#5a005a' d="M 0 0 L 2 1.5 L 0 3 Q 1 1.5 0 0" />
                                        </marker>
                                        <marker id="arrowhead33" markerWidth="2" markerHeight="3" refX="1.5" refY="1.5" orient="auto-start-reverse">
                                            <path strokeWidth="0" fill='#5a005a' d="M 0 0 L 2 1.5 L 0 3 Q 1 1.5 0 0" />
                                        </marker>
                                    </defs>      
                    </Fragment>
                )
            }

            {
                deltatOk === true && dt > 0 && (
                    <text dominantBaseline="middle" textAnchor="middle" x={((1000/frequence)*((width-80)/(temps))+35)+((dt)*((width-80)/(temps)))/2} y={height-54} fill='#5a005a' style={{fontFamily:'LatoBold', fontSize: "22px", fontWeight: "bold"}}>Δt = {String(parseFloat(dt).toFixed(1)).replace(".",",")} ms</text>
                )
            }

            

            {
                deltatOk === true && dt < -6 && (
                    <Fragment>
                        <line stroke='#5a005a' strokeWidth="4" x1={(((1000/frequence)+dt)*((width-80)/(temps))+35)+2} x2={(((1000/frequence)+dt)*((width-80)/(temps))+35-2)+((-1*dt)*((width-80)/(temps)))} y1={height-35} y2={height-35} markerEnd="url(#arrowhead32)" markerStart="url(#arrowhead33)"/>
                                    <defs>
                                        <marker id="arrowhead32" markerWidth="2" markerHeight="4" refX="1.5" refY="1.5" orient="auto">
                                            <path strokeWidth="0" fill='#5a005a' d="M 0 0 L 2 1.5 L 0 3 Q 1 1.5 0 0" />
                                        </marker>
                                        <marker id="arrowhead33" markerWidth="2" markerHeight="4" refX="1.5" refY="1.5" orient="auto-start-reverse">
                                            <path strokeWidth="0" fill='#5a005a' d="M 0 0 L 2 1.5 L 0 3 Q 1 1.5 0 0" />
                                        </marker>
                                    </defs>
                    </Fragment>
                )
            }

            {
                deltatOk === true && dt < 0 && (
                    <text dominantBaseline="middle" textAnchor="middle" x={(((1000/frequence)+dt)*((width-80)/(temps))+35)+((-1*dt)*((width-80)/(temps)))/2} y={height-54} fill='#5a005a' style={{fontFamily:'LatoBold', fontSize: "22px", fontWeight: "bold"}}>Δt = {String(parseFloat(-1*dt).toFixed(1)).replace(".",",")} ms</text>
                )
            }

            
            <Fonction width={width} height={height} color={'#ff0000'} fct={2} changefct={changefct}/>

            <text x={width*0.5-40} y="25" fill='#ff0000' style={{fontFamily:'LatoBold', fontSize: "25px", fontWeight: "bold"}}>Courant</text>

        </svg>
     );
}
 
export default Courant;