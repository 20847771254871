import { configureStore } from '@reduxjs/toolkit'
import uReducer from '../features/uSlice'
import fReducer from '../features/fSlice'
import iReducer from '../features/iSlice'
import tReducer from '../features/tSlice'
import phiReducer from '../features/phiSlice'
import deltatReducer from '../features/deltatSlice'
import dtReducer from '../features/dtSlice'

export const store = configureStore({
  reducer: {
      u : uReducer,
      f : fReducer,
      i : iReducer,
      t : tReducer,
      phi : phiReducer,
      deltat : deltatReducer,
      dt : dtReducer
  },
})