import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: JSON.parse(localStorage.getItem("deltatOk")) || false,
}

export const deltatSlice = createSlice({
  name: 'deltat',
  initialState,
  reducers: {
        change: (state) => {
            state.value = !state.value;
            localStorage.setItem("deltatOk", JSON.stringify(state.value));
        },
  },
})

// Action creators are generated for each case reducer function
export const { change } = deltatSlice.actions

export default deltatSlice.reducer