import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: parseFloat(localStorage.getItem('temps')) || 150,
}

export const tSlice = createSlice({
  name: 't',
  initialState,
  reducers: {
    plusT: (state) => {
        if(state.value < 200){
            state.value = parseInt(state.value+1);
            localStorage.setItem("temps", parseInt(state.value));
        }
    },
    moinsT: (state) => {
        if(state.value > 130){
            state.value = parseInt(state.value-1);
            localStorage.setItem("temps", parseInt(state.value));
        }
    }
  },
})

// Action creators are generated for each case reducer function
export const { plusT, moinsT } = tSlice.actions

export default tSlice.reducer