/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, {useState,useEffect} from 'react';
import Graphique from '../Graphique';
import Fonction from './Fonction';
import Checkbox from '../Checkbox';
import { useSelector } from 'react-redux';

const Puissance = ({x,y,width,height,modifFct}) => {

    const tension = useSelector((state) => state.u.value)
    const courant = useSelector((state) => state.i.value)
    const frequence = useSelector((state) => state.f.value)
    const temps = useSelector((state) => state.t.value)
    const dephasage = useSelector((state) => state.phi.value)
    
    const [xList, setxList] = useState([])

    const [yList, setyList] = useState([])

    const [yList2, setyList2] = useState([])

    const [yList3, setyList3] = useState([])

    const [deltaX, setdeltaX] = useState(0.1)

    const [check, setcheck] = useState(JSON.parse(localStorage.getItem("check")) || false)

    const [check2, setcheck2] = useState(JSON.parse(localStorage.getItem("check2")) || false)

    const [fct, setfct] = useState(3)

    const [config, setconfig] = useState({
        xMin : 0,
        xMax : temps,
        yMin : -1*tension*courant,
        yMax : tension*courant,
        grandeurY : "P",
        uniteY : "W",
        grandeurX : "t",
        uniteX : "ms",
    })

    useEffect(() => {
      if(check === false && check2 === false){
            setfct(3);
      }else if(check === true && check2 === true){
            setfct(33);
      }else if(check === true && check2 === false){
            setfct(31);
      }else if(check === false && check2 === true){
            setfct(32);
      }
    }, [check,check2])
    

    useEffect(() => {

        setconfig({
            xMin : 0,
            xMax : temps,
            yMin : -1*tension*courant,
            yMax : tension*courant,
            grandeurY : "P",
            uniteY : "W",
            grandeurX : "t",
            uniteX : "ms",
        })

        let x = 0;
        let liX = [];
        let liY = [];
        let liY2 = [];
        let liY3 = [];
      
        while(x <= config.xMax){
            liX.push(x);
            liY.push((tension*Math.cos(2*3.1415926*frequence*x*0.001)*courant*Math.cos(2*3.1415926*frequence*0.001*x-dephasage*3.1415926)));
            liY2.push((tension*courant/2)*Math.cos(dephasage*Math.PI) );
            liY3.push(( tension*courant/2 ));
            x += deltaX;
        }

        setxList(liX);
        setyList(liY);
        setyList2(liY2);
        setyList3(liY3);

    }, [courant,tension,frequence,temps,dephasage])

    const changefct = (value) => {
        modifFct(value);
    }

    const modifchecked = ()=> {
        setcheck(!check);
        localStorage.setItem("check", JSON.stringify(!check));
    }

    const modifchecked2 = ()=> {
        setcheck2(!check2);
        localStorage.setItem("check2", JSON.stringify(!check2));
    }
    
    return ( 
        <svg  className='puissance' x={x} y={y} width={width} height={height} >
            <rect x="0" y="0" width="100%" height="100%" rx="15" ry="15" fill='#FFFFFF' />

            <Graphique config={config} i={1} show={true} mode={1} line="3" lx={xList} ly={yList} ly2={yList2} ly3={yList3} colcourbe={'#800080'} colcourbe2={'#329a3d'} colcourbe3={'#ff6600'} ok2={check} ok3={check2} isEqual23={String(parseFloat(dephasage).toFixed(1)) === "0.0"} dxmax={20} dxmin={10} dymax={config.yMax > 60 ? 20 : ( config.yMax > 30 ? 10 : ( config.yMax > 10 ? 5 : 1 ) )} dymin={config.yMax > 60 ? 5 : ( config.yMax > 30 ? 2 : ( config.yMax > 10 ? 1 : 0.5 ) )} xmin={config.xMin} xmax={config.xMax} ymin={config.yMin} ymax={config.yMax} x0={38} y0={height/2-5} width={width-83} height={height-100} xLabel={`${config.grandeurX}(${config.uniteX})`} yLabel={`${config.grandeurY}(${config.uniteY})`}/>
            
            <Fonction width={width} height={height} color={'#800080'} fct={fct} changefct={changefct} />

            <text x={width*0.5-110} y="25" fill='#800080' style={{fontFamily:'LatoBold', fontSize: "25px", fontWeight: "bold"}}>Puissance instantanée</text>
            
            <Checkbox x={70} y={height-28} width="20" height="20" value={check} changeValue={modifchecked}/>
            <text x={100} y={height-11} fill='#329a3d' style={{fontFamily:'LatoBold', fontSize: "19px", fontWeight: "bold"}}>Puissance active —</text>
            
            <Checkbox x={290} y={height-28} width="20" height="20" value={check2} changeValue={modifchecked2}/>
            <text x={320} y={height-11} fill='#ff6600' style={{fontFamily:'LatoBold', fontSize: "19px", fontWeight: "bold"}}>Puissance apparente —</text>
            
        </svg>
     );
}
 
export default Puissance;