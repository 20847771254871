/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, {useState,useEffect} from 'react';
import Graphique from '../Graphique';
import Fonction from './Fonction';
import { useSelector } from 'react-redux';

const Tension = ({x,y,width,height,modifFct}) => {

    const tension = useSelector((state) => state.u.value)
    const frequence = useSelector((state) => state.f.value)
    const temps = useSelector((state) => state.t.value)
    const deltatOk = useSelector((state) => state.deltat.value)
    const dt = useSelector((state) => state.dt.value)

    const [xList, setxList] = useState([])

    const [yList, setyList] = useState([])

    const [deltaX, setdeltaX] = useState(0.1)

    const [config, setconfig] = useState({
        xMin : 0,
        xMax : temps,
        yMin : -1*tension,
        yMax : tension,
        grandeurY : "U",
        uniteY : "V",
        grandeurX : "t",
        uniteX : "ms",
    })

    useEffect(() => {

        setconfig({
            xMin : 0,
            xMax : temps,
            yMin : -1*tension,
            yMax : tension,
            grandeurY : "U",
            uniteY : "V",
            grandeurX : "t",
            uniteX : "ms",
        })

        let x = 0;
        let liX = [];
        let liY = [];
      
        while(x <= config.xMax){
            liX.push(x);
            liY.push((tension*Math.cos(2*3.1415926*frequence*x*0.001)));
            x += deltaX;
        }

        setxList(liX);
        setyList(liY);

    }, [tension,frequence,temps])
    

    const getValue = (x,courbe) => {
        console.log(`temps U : ${x}`);
        return tension*Math.cos(2*3.1415926*frequence*x*0.001);
    }

    const changefct = (value) => {
        modifFct(value);
    }
    
    return ( 
        <svg  className='tension' x={x} y={y} width={width} height={height} >
            <rect x="0" y="0" width="100%" height="100%" rx="15" ry="15" fill='#FFFFFF' />

            {
                deltatOk === true && dt < 0 && (
                    <rect x={(((1000/frequence)+dt)*((width-80)/(temps))+35)} y={35} width={((-1*dt)*((width-80)/(temps)))} height={height-35} fill='#ffff00' opacity="0.5"/>
                )
            }

            {
                deltatOk === true && dt > 0 && (
                    <rect x={((1000/frequence)*((width-80)/(temps))+35)} y={35} width={((dt)*((width-80)/(temps)))} height={height-35} fill='#ffff00' opacity="0.5"/>
                )
             } 

            <Graphique config={config} i={1} show={true} mode={1} line="3" lx={xList} ly={yList} colcourbe={'#0080ff'} dxmax={20} dxmin={10} dymax={config.yMax > 6 ? 2 : 1} dymin={config.yMax > 6 ? 0.5 : 0.2} xmin={config.xMin} xmax={config.xMax} ymin={config.yMin} ymax={config.yMax} x0={35} y0={height/2+5} width={width-80} height={height-70} xLabel={`${config.grandeurX}(${config.uniteX})`} yLabel={`${config.grandeurY}(${config.uniteY})`}/>
            
            <Fonction width={width} height={height} color={'#0080ff'} fct={1} changefct={changefct}/>

            <text x={width*0.5-40} y="25" fill='#0080ff' style={{fontFamily:'LatoBold', fontSize: "25px", fontWeight: "bold"}}>Tension</text>
            
        </svg>
     );
}
 
export default Tension;