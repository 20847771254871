/*
Auteur : Samuel JACQUINET
Cette œuvre est mise à disposition selon les termes de la Licence Creative Commons Attribution – Pas d’Utilisation Commerciale – Pas de Modification 4.0 International.
*/
import React, { useState, useEffect } from 'react'

const Infos = ({showInfo,posX,posY,width,height,grandeur,grandeur2,unite,unite2,ymax,ymin,uniteF,mode}) => {
    
    const [largeur, setlargeur] = useState(220)
    const [hauteur, sethauteur] = useState(25)

    if(showInfo === true){
        if(ymin === 0){
            if(posY >= (ymax-ymin)/2){
                return (
                    <svg x={ 30+width*0.5-largeur*0.5 } y={height} width={largeur} height={hauteur}>
                        <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                        <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>{grandeur} = {String(parseFloat(posY).toFixed(1) === -0.0 ? 0.0 : parseFloat(posY).toFixed(1)).replace(".",",")} {unite} | {mode === 1 ? `${grandeur2} = ${String(parseFloat(posX).toFixed(1)).replace(".",",")} ${unite2}` : `f = ${String(uniteF === "Hz" ? parseFloat(posX).toFixed(1) : parseFloat(posX).toFixed(2)).replace(".",",")} ${uniteF}`}  </text>
                    </svg>
                )
            }else{
                return (
                    <svg x={ 30+width*0.5-largeur*0.5 } y={hauteur+30} width={largeur} height={hauteur}>
                        <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                        <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>{grandeur} = {String(parseFloat(posY).toFixed(1) === -0.0 ? 0.0 : parseFloat(posY).toFixed(1)).replace(".",",")} {unite} | {mode === 1 ? `${grandeur2} = ${String(parseFloat(posX).toFixed(1)).replace(".",",")} ${unite2}` : `f = ${String(uniteF === "Hz" ? parseFloat(posX).toFixed(1) : parseFloat(posX).toFixed(2)).replace(".",",")} ${uniteF}`}</text>
                    </svg>
                )
            }
        }else{
            if(posY >= 0){
                return (
                    <svg x={ 30+width*0.5-largeur*0.5 } y={height-height/5} width={largeur} height={hauteur}>
                        <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                        <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>{grandeur} = {String(parseFloat(posY).toFixed(1) === -0.0 ? 0.0 : parseFloat(posY).toFixed(1)).replace(".",",")} {unite} | {grandeur2} = {String(parseFloat(posX).toFixed(1)).replace(".",",")} {unite2}</text>
                    </svg>
                )
            }else{
                return (
                    <svg x={ 30+width*0.5-largeur*0.5 } y={height/5+30} width={largeur} height={hauteur}>
                        <rect x="0" y="0" width="100%" height="100%"  rx="8" ry="8" fill='#000000' opacity={0.7} />
                        <text x="50%" y="55%" fill="white" dominantBaseline="middle" textAnchor="middle" style={{fontFamily:'LatoBold', fontSize: "18px", fontWeight: "bold"}}>{grandeur} = {String(parseFloat(posY).toFixed(1) === -0.0 ? 0.0 : parseFloat(posY).toFixed(1)).replace(".",",")} {unite} | {grandeur2} = {String(parseFloat(posX).toFixed(1)).replace(".",",")} {unite2}</text>
                    </svg>
                )
            }
        }
    }else{
        return null;
    }
}
 
export default Infos;

